<template>
  <div class="main">
    <h3>歡迎回來，綁定Line即可享有更多喬米服務</h3>
    <div class="other_login">
      <div class="icon"><img class="otherBtn" @click="lineLogin()" :src="require('/src/assets/image/Shared/Login/Single_Line.png')" /></div>
    </div>
    <div class="mt-5">
      <el-button @click="remindBindLine()">不再詢問</el-button>
      <el-button @click="cancelTempRemindBindLine()" type="danger">取消</el-button>
    </div>
  </div>
</template>

<script>
import Storage from "../../../models/storage";

export default {
  name: "BindLine",
  data() {
    return {};
  },
  async mounted() {
    this.log_PageInfo("Login_BindLine", "13");
  },
  methods: {
    lineLogin() {
      let user = Storage.get("user");
      user.tempRemindBindLine = false;
      Storage.set("user", user);
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.VUE_APP_LINE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_CURRENT_DOMAIN}LineAuth&state=12345abcde&scope=profile%20openid`;
    },
    remindBindLine() {
      const vm = this;
      let data = {
        RemindBindLine: false,
      };
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/Account/remindBindLine`, data)
        .then(() => {
          let user = Storage.get("user");
          user.remindBindLine = false;
          Storage.set("user", user);
          this.notify("success", "不再提醒綁定Line！");
          this.$router.push("/ChargeMeContactBook/ClassRoomCenter");
        })
        .catch(err => {
          console.log(err);
          this.notify("error", `不再提醒綁定Line，設定失敗！`);
        })
    },
    cancelTempRemindBindLine() {
      let user = Storage.get("user");
      user.tempRemindBindLine = false;
      Storage.set("user", user);
      this.$router.push("/ChargeMeContactBook/ClassRoomCenter");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 1rem;
  height: 100%;
  margin: auto;
  h3 {
    font-size: 1.5rem;
    margin: 1rem 0 3rem;
    font-weight: bold;
    line-height: 3rem;
  }
}
.other_login {
  display: flex;
  justify-content: center;
  .icon {
    margin: 0.5rem 1.5rem;
  }
}
.icon {
  border-radius: 50%;
  box-shadow: 1px 1px 3px 1px #b0b0b0;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px #565656;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
